var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MissionCard",
    { staticClass: "jeopardai-final-category-mobile" },
    [
      _c("ResizableText", {
        staticClass: "jeopardai-final-category-mobile__text",
        attrs: { message: _vm.message },
      }),
      _vm.isViwerSelecting
        ? _c("OptionsList", {
            attrs: { show: "", options: _vm.options },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function (ref) {
                    var option = ref.option
                    return [
                      _c(
                        "ChoiceBtn",
                        {
                          attrs: {
                            inactive: _vm.value != null,
                            selected: option === _vm.value,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submit(option)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(option) + " ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1263933768
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }