import { computed } from "@vue/composition-api"

import useStore from "@/use/useStore"

export default function useIsViewerHostLike() {
  const { store } = useStore()

  const isViewerHostLike = computed(
    () => store.getters["group/isViewerHostLike"]
  )

  return { isViewerHostLike }
}
