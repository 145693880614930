import { computed, ref } from "@vue/composition-api"
import { chain, sumBy } from "lodash"
import { child, serverTimestamp, set, update } from "firebase/database"

import Team from "@shared/Team"
import useTeams from "@/use/useTeams"

import useViewer from "./useViewer"
import useJeopardAiRound from "./useJeopardAiRound"
import useJeopardAiAnswers from "./useJeopardAiAnswers"
import useIsViewerHostLike from "./useIsViewerHostLike"
import useJeopardAiStateRef from "./useJeopardAiStateRef"
import useJeopardPredefined from "./useJeopardPredefined"

export default function useJeopardAiFinaleCategory() {
  const { teams } = useTeams()
  const { answers } = useJeopardAiAnswers()
  const { round } = useJeopardAiRound()
  const { ref: stateRef } = useJeopardAiStateRef(round)
  const { viewer } = useViewer()
  const { isViewerHostLike } = useIsViewerHostLike()
  const { predefined, getFinalQuestion, finalQuestionsByCategory } =
    useJeopardPredefined()

  const isViwerSelecting = computed(
    () => loser.value?.id === viewer.value?.teamID || isViewerHostLike.value
  )
  const loser = computed<{ name: string; id: string } | undefined>(() => {
    const grouped = chain(answers.value)
      .groupBy(answer => answer.teamId)
      .value()

    const [first] = chain(Object.values(teams.value))
      .reduce((acc, team) => {
        if (!Team.isSpecialPurpose(team)) {
          acc.push({
            teamId: team.id,
            amount: sumBy(grouped[team.id], "amount")
          })
        }
        return acc
      }, [])
      .sortBy("id")
      .sortBy("amount")
      .value()

    return teams.value?.[first?.teamId]
  })

  const categoryRef = computed(() => child(stateRef.value, "finale/category"))

  const message = computed(() => {
    if (isViwerSelecting.value) {
      return "Which category would you like to play in the finale?"
    }

    if (loser.value != null) {
      return `${loser.value.name} is selecting the final category!`
    }

    return "We don't have any progress yet!"
  })

  const value = ref<string | undefined>()

  function submit(option: string) {
    value.value = option
    if (predefined.value) {
      update(stateRef.value, {
        finale: { ...getFinalQuestion(option), id: serverTimestamp() },
        questionId: serverTimestamp()
      })
    } else {
      set(categoryRef.value, option)
    }
  }

  return {
    message,
    value,
    submit,
    finalQuestionsByCategory,
    isViewerHostLike,
    isViwerSelecting,
    loser
  }
}
